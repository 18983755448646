module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dorwij Nerda","short_name":"Dorwij Nerda","description":"Od programistów dla programistów","start_url":"/pl/","background_color":"#4044FE","theme_color":"#4044FE","display":"standalone","icon":"src/icons/favicon.png","crossOrigin":"use-credentials","lang":"pl","localize":[{"start_url":"/en/","lang":"en","name":"Catch a Geek","short_name":"Catch a Geek","description":"From developers, for developers"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"929afa118d4fc7c65076ce48778ccae7"},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-0XZ37FY87W"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
