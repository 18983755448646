// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from "@chakra-ui/react"
import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const outline = defineStyle({
  border: '2px', // change the appearance of the border
  fontSize: 'sm', // change the font size
  boxShadow: 'lg', // change the shadow
  _hover: {
    transform: 'scale(1.1)',
    color: '',
    background: '',
  },
})

const solid = defineStyle({
  fontSize: 'sm', // change the font size
  boxShadow: 'lg', // change the shadow
  _hover: {
    transform: 'scale(1.1)',
    color: '',
    background: '',
  },
})

const ghost = defineStyle({
  fontSize: 'sm', // change the font size
  rounded: 'full', // change the border radius
  _active: {
    background: "",
  },
  _hover: {
    transform: 'scale(1.1)',
    color: '',
    background: '',
  },
})

export const buttonTheme = defineStyleConfig({
  variants: { outline, solid, ghost },
})

const sizes = {
  max: 'max-content',
  min: 'min-content',
  full: '100%',
  '4xs': '12rem',
  '3xs': '14rem',
  '2xs': '16rem',
  xs: '20rem',
  sm: '24rem',
  md: '28rem',
  lg: '32rem',
  xl: '36rem',
  '2xl': '42rem',
  '3xl': '48rem',
  '4xl': '56rem',
  '5xl': '64rem',
  '6xl': '72rem',
  '7xl': '80rem',
  '8xl': '90rem',
  container: {
    sm: '640px',
    md: '876px',
    lg: '1024px',
    xl: '1200px',
  },
}

const theme = {
  components: { Button: buttonTheme },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  colors: {
    blue: {
      500: "#4043FF",
      700: "#292BA3",
    },
  },
  sizes,
  styles: {
    global: {
      "a:hover": {
        textDecoration: 'none !important',
      },
      "button[disabled]:hover": {
        transform: 'scale(1)',
        background:"linear(to-r, blue.500, blue.700)",
      }
    }
  }
}

export default extendTheme(theme) // or extendBaseTheme
